<template>
  <Modal
    name="temporarily_unavailable"
    title="Акция завершена!"
    size="md"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="success_modal">
        <div class="modal__text" style="text-align: center">
          Cпасибо за участие!
          <br />Вручение призов осуществляется по&nbsp;15&nbsp;октября 2024<span style="text-transform: lowercase;">г</span>.
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import "jquery";

import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/styles/main.scss";
import VModal from "vue-js-modal";

import typograf from "./utils/filters";
import VueScrollactive from "vue-scrollactive";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VTooltip from "v-tooltip";
import VueSmoothScroll from "vue2-smooth-scroll";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import LoadScript from "vue-plugin-load-script";
import DadataSuggestions from "vue-dadata-suggestions";
import VueMeta from "vue-meta";
import VueResource from "vue-resource";
import VueYandexMetrika from "vue-yandex-metrika";

Vue.use(VueResource);

Vue.use(VueMeta);
Vue.use(DadataSuggestions, {
  token: "e3468f8470bd9bff626cc9a80dfc9cceb5d01020",
  type: "ADDRESS",
});
Vue.use(VueYandexMetrika, {
  id: 97909253,
  router: router,
  env: "production",
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  debug: true,
});
Vue.use(LoadScript);
Vue.use(VueScrollactive);
Vue.component("v-select", vSelect);
Vue.use(VTooltip);
Vue.filter("text", typograf);
Vue.use(VueSmoothScroll, {
  offset: -150,
  duration: 400,
  updateHistory: false,
});
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(Vuelidate);

function gtmEvent(
  label = " ",
  site_section = " ",
  action = "click",
  eventContext = null
) {
  window?.dataLayer?.push({
    event: "interaction",
    site_section,
    action,
    label,
    eventContext,
  });
}
Vue.config.productionTip = false;
/* eslint-disable */
Vue.mixin({
  methods: {
    gtmEvent: (label, action, category) => {
      window.dataLayer?.push({
        event: "gaEvent",
        eventCategory: category || "",
        eventAction: action || "",
        eventLabel: label || "",
      });
    },
    rememberSoc(item) {
      localStorage.socialName = item;
    },
  
    adhighRegistration: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
        var p = {
          type: "CONVERSION",
          /* config START */
          site_id: "9703",
          order: [],
          transaction_id: "",
          revenue: "",
          pixel_id: "Conv_Zaregistr_Gr",
          /* config END */
        };
        __GetI.push(p);
        var domain =
          typeof __GetI_domain == "undefined" ? "px.adhigh.net" : __GetI_domain;
        var src =
          ("https:" == document.location.protocol ? "https://" : "http://") +
          domain +
          "/t.js";
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(script, s);
      })();
    },
    adhighChatBot: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
        var p = {
          type: "VIEW",
          /* config START */
          site_id: "9703",
          product_id: "",
          product_price: "",
          category_id: "",
          pixel_id: " Track_ChatBot_Gr ",
          /* config END */
        };

        __GetI.push(p);
        var domain =
          typeof __GetI_domain == "undefined" ? "px.adhigh.net" : __GetI_domain;
        var src =
          ("https:" == document.location.protocol ? "https://" : "http://") +
          domain +
          "/t.js";
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(script, s);
      })();

      generalPixel.postClick('a2');
    },
    adhighRegistrationSuccess: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
        var p = {
          type: "CONVERSION",
          /* config START */
          site_id: "9703",
          order: [],
          transaction_id: "",
          revenue: "",
          pixel_id: "Conv_Otpr_Reg_Gr",
          /* config END */
        };
        __GetI.push(p);
        var domain =
          typeof __GetI_domain == "undefined" ? "px.adhigh.net" : __GetI_domain;
        var src =
          ("https:" == document.location.protocol ? "https://" : "http://") +
          domain +
          "/t.js";
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(script, s);
      })();

      generalPixel.postClick('a1');
    },
    adhighCheck: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
        var p = {
          type: "CONVERSION",
          /* config START */
          site_id: "9703",
          order: [],
          transaction_id: "",
          revenue: "",
          pixel_id: "Conv_Zareg_Check_Gr",
          /* config END */
        };
        __GetI.push(p);
        var domain =
          typeof __GetI_domain == "undefined" ? "px.adhigh.net" : __GetI_domain;
        var src =
          ("https:" == document.location.protocol ? "https://" : "http://") +
          domain +
          "/t.js";
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(script, s);
      })();
    },
    adhighCheckSeccess: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
        var p = {
          type: "CONVERSION",
          /* config START */
          site_id: "9703",
          order: [],
          transaction_id: "",
          revenue: "",
          pixel_id: "Conv_Otpr_Check_Gr",
          /* config END */
        };
        __GetI.push(p);
        var domain =
          typeof __GetI_domain == "undefined" ? "px.adhigh.net" : __GetI_domain;
        var src =
          ("https:" == document.location.protocol ? "https://" : "http://") +
          domain +
          "/t.js";
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(script, s);
      })();
    },
    fbGoal() {
      generalPixel.postClick('a3');
    },
    toCheckUpload() {
      const t = this;
      t.adhighCheck();
      this.gtmEvent("click", "register_receipt_1_screen");
      console.log(this.$store.getters.endTime);
      
      if (this.$store.getters.endTime) {
        this.$modal.show('temporarily_unavailable');
      } else {
        if (this.$store.getters.user) {
          if (this.$store.getters.user?.bad === 1) {
            this.$modal.show("common_error", {
              title: "Внимание",
              text: "Уважаемый участник! <br>В&nbsp;связи с&nbsp;нарушениями Правил Акции мы&nbsp;вынуждены заблокировать Ваш аккаунт и&nbsp;аннулировать право на&nbsp;получение Призов согласно п.9.1 Правил Акции."
            });
          } else {
            let apmButton = document.querySelector(
              "#apm-scan-qr .apm-action-button"
            );
            if (apmButton) apmButton.click();
            setTimeout(() => {
              const closeBtn = document.getElementById("apm-close-widget-button");
    
              console.log(closeBtn);
              closeBtn.addEventListener("click", function () {
                if (!localStorage.getItem('checkDone')) {
                  t.gtmEvent("do", "left_receipt_registration");
                  t.$metrika.reachGoal('left_receipt_registration');
                  localStorage.setItem('checkDone', false);
                }
              });
            }, 1000);
          }
          
        } else {
          this.$modal.show("authorization");
        }
      }
    },
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
